<template>
  <div class="drawer">
    <div class="head-row" @click="closeDrawer"><i class="iconfont">&#xe615;</i></div>

    <div class="input-wrapper">
      <div class="icon">
        <i class="iconfont">&#xe6e1;</i>
      </div>
      <div class="input">
        <input type="text" v-model="form.email" placeholder="搜索候选人姓名、手机号、邮箱" />
      </div>
    </div>

    <template v-if="showRecommendStatus">
      <div class="input-lable">筛选结果</div>
      <div class="select-wrapper" @click="showFilterPopup = true">
        <div class="input">{{ recommendStatus }}</div>
        <div class="icon">
          <i class="iconfont">&#xe604;</i>
        </div>
      </div>
    </template>

    <div class="input-lable">职位</div>

    <div class="select-wrapper" @click="showPositionPopup = true">
      <div class="input">{{ positionName }}</div>
      <div class="icon">
        <i class="iconfont">&#xe604;</i>
      </div>
    </div>

    <div class="input-lable">学历</div>

    <div class="educate-wrapper">
      <div class="educate-item" @click="showEducationStartPopup = true">
        <div class="input">{{ eduBeginName }}</div>
        <div class="icon">
          <i class="iconfont">&#xe604;</i>
        </div>
      </div>
      <div class="center-gap">-</div>
      <div class="educate-item" @click="showEducationEndPopup = true">
        <div class="input">{{ eduEndName }}</div>
        <div class="icon">
          <i class="iconfont">&#xe604;</i>
        </div>
      </div>
    </div>

    <div class="input-lable">工作年限</div>

    <div class="work-year-wrapper">
      <div class="year-item" @click="showStartPopup = true">
        <div class="input">{{ startWorkYear }}</div>
        <div class="icon">
          <i class="iconfont">&#xe604;</i>
        </div>
      </div>
      <div class="center-gap">-</div>
      <div class="year-item" @click="showEndPopup = true">
        <div class="input">{{ endWorkYear }}</div>
        <div class="icon">
          <i class="iconfont">&#xe604;</i>
        </div>
      </div>
    </div>

    <div class="bottom-row">
      <div class="left" @click="closeDrawer">取消</div>
      <div class="right" @click="submit">搜索</div>
    </div>

    <!--面试结果-->
    <van-popup v-model="showFilterPopup" position="bottom">
      <van-picker
        show-toolbar
        :columns="filterCloums"
        @confirm="
          (_value, _index) => {
            return onFilterPickConfirm(_index)
          }
        "
        @cancel="showFilterPopup = false"
      />
    </van-popup>
    <!--职位-->
    <van-popup v-model="showPositionPopup" position="bottom">
      <van-picker
        show-toolbar
        :columns="positionColumns"
        @confirm="
          (_value, _index) => {
            return onPositionPickConfirm(_index)
          }
        "
        @cancel="showPositionPopup = false"
      />
    </van-popup>
    <!--工作年限-->
    <van-popup v-model="showStartPopup" position="bottom">
      <van-picker
        show-toolbar
        :columns="startWorkYearColumns"
        @confirm="
          (_value, _index) => {
            return onStartWorkYearPickConfirm(_index)
          }
        "
        @cancel="showStartPopup = false"
      />
    </van-popup>
    <van-popup v-model="showEndPopup" position="bottom">
      <van-picker
        show-toolbar
        :columns="endWorkYearColumns"
        @confirm="
          (_value, _index) => {
            return onEndWorkYearPickConfirm(_index)
          }
        "
        @cancel="showEndPopup = false"
      />
    </van-popup>
    <!--学历筛选-->
    <van-popup v-model="showEducationStartPopup" position="bottom">
      <van-picker
        show-toolbar
        :columns="startEducationColumns"
        @confirm="
          (_value, _index) => {
            return onStartEducationPickConfirm(_index)
          }
        "
        @cancel="showEducationStartPopup = false"
      />
    </van-popup>
    <van-popup v-model="showEducationEndPopup" position="bottom">
      <van-picker
        show-toolbar
        :columns="endEducationColumns"
        @confirm="
          (_value, _index) => {
            return onEndEducationPickConfirm(_index)
          }
        "
        @cancel="showEducationEndPopup = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { recommendPositionList, interviewPositionList } from '@/api/candidate'

export default {
  props: {
    showRecommendStatus: {
      type: Boolean,
      default() {
        return false
      },
    },
    //0.待筛选 1.已筛选 3.已失效
    recommendType: {
      type: Number,
    },
    drawerType: {
      type: Number,
    },
  },
  data() {
    return {
      recommendStatus: '',
      positionId: '',
      startWorkYear: '',
      endWorkYear: '',
      positionName: '',
      showPositionPopup: false,
      showStartPopup: false,
      showEndPopup: false,
      showFilterPopup: false,
      positionList: [],
      form: {
        positionId: '',
        startWorkYear: '',
        endWorkYear: '',
        recommendStatus: '',
        email: '',
        eduEnd: 0,
        eduBegin: 0,
      },
      workYearArray: ['0年', '1年', '2年', '3年', '4年', '5年', '6年', '7年', '8年', '9年', '10年', '11年', '12年', '13年', '14年', '15年', '16年', '17年', '18年', '19年', '20年'],
      filterCloums: ['通过', '拒绝', '待定'],
      educateCloums: ['初中及以下', '高中中专中技', '大专', '本科', '硕士', '博士', 'MBA'],
      eduBeginName: '',
      eduEndName: '',
      showEducationStartPopup: false,
      showEducationEndPopup: false,
    }
  },
  mounted() {
    this.requestPositionList()
  },
  computed: {
    startWorkYearColumns() {
      if (this.form.endWorkYear) {
        return this.workYearArray.filter((item, index) => {
          console.log(item)
          return index <= this.form.endWorkYear
        })
      } else {
        return this.workYearArray
      }
    },
    endWorkYearColumns() {
      if (this.form.startWorkYear) {
        return this.workYearArray.filter((item, index) => {
          return index >= this.form.startWorkYear
        })
      } else {
        return this.workYearArray
      }
    },
    startEducationColumns() {
      if (this.form.eduEnd) {
        return this.educateCloums.slice(0, this.form.eduEnd)
      } else {
        return this.educateCloums
      }
    },
    endEducationColumns() {
      if (this.form.eduBegin) {
        return this.educateCloums.slice(this.form.eduBegin - 1)
      } else {
        return this.educateCloums
      }
    },
    positionColumns() {
      return this.positionList.map(item => {
        return item.positionName
      })
    },
  },
  methods: {
    onStartEducationPickConfirm(index) {
      this.eduBeginName = this.startEducationColumns[index]
      let findIndex = this.educateCloums.findIndex(item => item === this.eduBeginName)
      this.form.eduBegin = findIndex + 1
      this.showEducationStartPopup = false
    },
    onEndEducationPickConfirm(index) {
      this.eduEndName = this.endEducationColumns[index]
      let findIndex = this.educateCloums.findIndex(item => item === this.eduEndName)
      this.form.eduEnd = findIndex + 1
      this.showEducationEndPopup = false
    },
    submit() {
      this.$emit('updateFilterForm', this.form)
      this.afterCloseDrawer()
    },

    afterCloseDrawer() {
      this.$emit('updateShowDrawer', false)
    },

    onFilterPickConfirm(index) {
      this.recommendStatus = this.filterCloums[index]
      if(index === 2) {
        index = 3
      }
      this.form.recommendStatus = index + 1
      this.showFilterPopup = false
    },

    requestPositionList() {
      if (this.drawerType === 1) {
        recommendPositionList({ recommendType: this.recommendType }).then(res => {
          if (res && res.code === 0) {
            this.positionList = res.data
          }
        })
      } else {
        interviewPositionList({ interviewType: this.recommendType }).then(res => {
          if (res && res.code === 0) {
            this.positionList = res.data
          }
        })
      }
    },

    closeDrawer() {
      console.log('close')
      this.afterCloseDrawer()
    },

    onPositionPickConfirm(index) {
      this.form.positionId = this.positionList[index].positionId
      this.positionName = this.positionList[index].positionName
      this.showPositionPopup = false
    },

    onStartWorkYearPickConfirm(index) {
      this.startWorkYear = this.startWorkYearColumns[index]
      this.form.startWorkYear = this.startWorkYearColumns[index].substr(0, this.startWorkYearColumns[index].length - 1)
      this.showStartPopup = false
    },

    onEndWorkYearPickConfirm(index) {
      this.endWorkYear = this.endWorkYearColumns[index]
      this.form.endWorkYear = this.endWorkYearColumns[index].substr(0, this.endWorkYearColumns[index].length - 1)
      this.showEndPopup = false
    },
  },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'iconfont'; /* project id 2013799 */
  src: url('//at.alicdn.com/t/font_2013799_8y1fhhnxh62.eot');
  src: url('//at.alicdn.com/t/font_2013799_8y1fhhnxh62.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_2013799_8y1fhhnxh62.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_2013799_8y1fhhnxh62.woff') format('woff'), url('//at.alicdn.com/t/font_2013799_8y1fhhnxh62.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_2013799_8y1fhhnxh62.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.drawer {
  .head-row {
    width: 100%;
    text-align: left;
    background-color: #efeff4;
    padding-left: 0.1rem;
    height: 0.8rem;
    line-height: 0.8rem;

    i {
      color: #1890ff;
      font-size: 0.6rem;
    }
  }

  .input-wrapper {
    width: 90%;
    margin: 0.8rem auto 0 auto;
    border: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    border-radius: 0.1rem;
    padding: 0.1rem 0.1rem 0.05rem 0.1rem;
    display: flex;
    align-items: center;
    .icon {
      padding: 0 0.1rem;
      i {
        color: #1890ff;
        font-size: 0.5rem;
      }
    }
    .input {
      width: 100%;

      input::input-placeholder {
        text-align: center;
      }

      input {
        height: 0.7rem;
        width: 90%;
        //height: 100%;
        border: 0;
        font-size: 0.4rem;
      }
    }
  }

  .input-lable {
    color: #333333;
    font-size: 0.32rem;
    width: 90%;
    margin: 0.5rem auto 0 auto;
    text-align: left;
    font-weight: 600;
  }

  .select-wrapper {
    width: 90%;
    margin: 0.5rem auto 0 auto;
    border: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    border-radius: 0.1rem;
    padding: 0rem 0.1rem;
    display: flex;
    align-items: center;
    min-height: 0.8rem;

    .input {
      width: 100%;
      text-align: left;
      font-size: 0.4rem;
      padding: 0.1rem 0 0.1rem 0.2rem;
    }
    .icon {
      padding: 0 0.1rem;
      i {
        color: #e0e0e0;
        font-size: 0.5rem;
      }
    }
  }

  .work-year-wrapper {
    width: 90%;
    margin: 0.5rem auto 0 auto;
    display: flex;
    justify-content: space-between;
    .year-item {
      border-radius: 0.1rem;
      flex: 2;
      border: 0.03rem solid #e0e0e0;
      display: flex;
      height: 0.8rem;
      line-height: 0.8rem;
      padding-left: 0.2rem;
      .input {
        width: 100%;
        text-align: left;
        font-size: 0.4rem;
        display: inline-block;
        vertical-align: middle;
      }
      .icon {
        padding: 0 0.1rem;
        display: inline-block;
        vertical-align: middle;
        i {
          color: #e0e0e0;
          font-size: 0.5rem;
        }
      }
    }
    .center-gap {
      font-size: 0.4rem;
      height: 0.8rem;
      line-height: 0.8rem;
      color: #333333;
      flex: 1;
      text-align: center;
    }
  }

  .educate-wrapper {
    width: 90%;
    margin: 0.5rem auto 0 auto;
    display: flex;
    justify-content: space-between;
    .educate-item {
      border-radius: 0.1rem;
      flex: 2;
      border: 0.03rem solid #e0e0e0;
      display: flex;
      height: 0.8rem;
      line-height: 0.8rem;
      padding-left: 0.2rem;
      .input {
        width: 100%;
        text-align: left;
        font-size: 0.4rem;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
      }
      .icon {
        padding: 0 0.1rem;
        display: inline-block;
        vertical-align: middle;
        i {
          color: #e0e0e0;
          font-size: 0.5rem;
        }
      }
    }
    .center-gap {
      font-size: 0.4rem;
      height: 0.8rem;
      line-height: 0.8rem;
      color: #333333;
      flex: 1;
      text-align: center;
    }
  }

  .bottom-row {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    font-size: 0.4rem;

    div {
      flex: 1;
      padding: 0.45rem 0;
      cursor: pointer;
    }
    .left {
      text-align: center;
      background-color: #efeff4;
      color: #333333;
    }
    .right {
      text-align: center;
      background-color: #1890ff;
      color: #ffffff;
    }
  }
}
</style>
