<template>
  <div class="float-button" v-drag="{ right: '18px', bottom: '108px' }">
    <slot></slot>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.float-button {
  width: 48px;
  height: 266px;

  img {
    // width: 100%;
  }
  .float-wrapper{
    height: auto;
    border-radius: 24px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(120, 120, 120, 0.3);
    >.float-item{
      margin-top: 20px 0;
    }
  }
}
</style>
